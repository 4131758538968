.verify-text-style {
  background-color: inherit;
  position: absolute;
  right: 0px;
  height: 42px;
  right: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.0025em;

  /* Alert/Success/4 */

  color: #269940;
  top: 0;
}
.verify-loader {
  position: absolute;
  right: 9px;
  top: 6px;
  animation: styles_rotate__37KEX 3s linear infinite;
  z-index: 2;

  width: 32px;
  height: 32px;
}
.verified-icon-style {
  position: absolute;
  right: 12px;
  top: 8px;
}
.verify-loader-circle {
  color: #d9d9d9;
}

@media only screen and (min-width: 1024px) {
  .verified-icon-style {
    top: 50%;
    transform: translateY(-50%);
  }
}
