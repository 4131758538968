.IconHeightWidth24 {
  height: 24px;
  width: 24px;
}
.IconHeightWidth20 {
  height: 20px;
  width: 20px;
}
.footer-arya2 a {
  color: white !important;
}

.text-start {
  text-align: start;
}
