.header-height {
    height: 96px;
  }
  .inputBorder {
    border: 1px solid #e1e3e3 !important;
  }
  .input-title1 {
    height: 14px;
  
    /* Body 2/Semibold */
  
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
   
    /* identical to box height */
  
    letter-spacing: 0.0025em;
  
    /* Text/Dark/9 */
  
    color: #7c878b;
  }
  .input-style {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    padding: 12px;
    margin-top: 6px;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    height: 40px;
  }
  .manage-notify-card-style {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .input-style-disable {
    background: #eeeeee;
  }
  
  .input-style-error {
    border: 1px solid #d42015;
  }
  .input-error-style {
    margin-top: 4px;
    padding-top: 0px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    
    /* identical to box height */
  
    letter-spacing: 0.004em;
  
    /* Alert/Danger/4 */
  
    color: #d42015;
  }
  .h-43{
    height: 43px;
  }
.h-40{
    height: 40px;
  }
  .hr-line{
  margin-left: -16px;
  margin-right: -16px;
  border-top: 2px solid #D9D9D9;
}
.mt-32{
    margin-top:32px;
}

  .addButton {
    width: 400px;
    height: 40px;
    padding: 11px 20px 11px 20px;
    border-radius: 6px;
    background: #0e5b48;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    margin-top: 16px;
  }
  
  .mobAddButton {
    min-width: 312px;
    padding: 11px 20px 11px 20px;
    border-radius: 6px;
    background: #0e5b48;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    margin-top: 16px;
  }
  .verfiyTextStyle{
    top:6px;
  }
  .grayButton {
    background-color: #b8bdbd;
  }
  .mb-60{
    margin-bottom:60px
  }

  @media only screen and (min-width: 1024px) {
    .input-title1 {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.005em;
    }
    .input-style {
      height: 43px;
      padding: 12px;
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.005em;
      margin-top:8px
    }
    .verify-check-icon {
      top: 36% !important;
    }
    .imageClassName{
    width: 18px;
    }
  }