.custom-checkbox {
  margin: 0;
  height: 16px;
}
.custom-checkbox > span {
  display: inline-block;
  border: 1px solid #7c878b;
  width: 16px;
  height: 16px;
  border-radius: 2px;
}
.custom-checkbox input {
  appearance: none;
  display: none;
}

.custom-checkbox input:checked + span {
  content: " ";
  width: 16px;
  height: 16px;
  background-image: url("../../Images/CommonImages/custom-chkbox-tick.svg");
  background-color: #0e5b48;
  border: 1px solid #0e5b48;
  background-repeat: no-repeat;
  background-position: 48% 50%;
}
.custom-checkbox input:disabled + span {
  content: " ";
  width: 16px;
  height: 16px;

  border: 1px solid #b8bdbd;
}
.custom-checkbox input:checked:disabled + span {
  content: " ";
  width: 16px;
  height: 16px;
  background-image: url("../../Images/CommonImages/custom-chkbox-tick.svg");
  background-color: #677373;

  border: 1px solid #b8bdbd;
  background-repeat: no-repeat;
  background-position: 48% 50%;
}
