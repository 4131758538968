.add-btn{
    padding-top:10.5px;
    padding-bottom: 10.5px;
    width: 164px;
    height: 40px;
}

.w-5{
    width: 5%;
}
.w-90{
    width: 90%;
}
.delete-icon-style{
    height: 20px;
    width: 20px;
}
.text-truncate-file-upload {
    max-width: calc(100vw - 112px);
    display: block !important;
  
    /* text-overflow: ellipsis; */
    /* display: -webkit-box; */
    /* -webkit-line-clamp: 1; */
    /* /* -webkit-box-orient: vertical; */
  }
  .input-border {
    border: 1px solid #e1e3e3 !important;
  }
  .hw18 {
    height: 18px;
    width: 18px;
  }
  .desk-input-style {
    border: 1px solid #e3e3e3 !important;
    height: 44px;
  }
  .pox-password {
    -webkit-text-security: disc;
    -moz-webkit-text-security: disc;
    -moz-text-security: disc;
  }
  .wid360{
    width: 302px;
    height: 41px;
  }  
  .grid-container {
    display: grid;
    column-gap: 3%;
    row-gap: 16px;
    grid-template-columns: 49% 49%;
    padding:16px;
  }
  .grid-title{
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #111313;
  }
  .grid-Subtitle{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #7C878B;
  }
  
  .success-faild-icon{
    width: 40px;
    height: 40px;
  }
  .failed-btn-styles{
    width: 208px;
    height: 41px;
    padding:11px 0px;
  } 
  .green-border-btn-style{
      white-space: nowrap;
      border: 1px solid #0E5B48;
    padding-left: 6px;
    background-color: #fff;
    padding-right: 6px;
  }
   .back-arrow {
    height: 24px;
    width: 24px;
  }
  .header-style {
    height: 64px;
  }
  .mob-astrik{
    margin-left: 2px;
    margin-top: -8px!important;
  }
  .mt4{
    margin-top:4px
  }
  .padding-right-left18{
    padding-right: 18px;
    padding-left:18px
  }
  .verify-padding{
    padding: 50px 32px;
  }
  .margin-top32{
    margin-top:32px;
  }
  .mobile-bank-card{
    box-shadow: 0px 4px 8px 0px #0000001A;
    border: 1px solid #E1E3E3;
    background: #FFFFFF;
  }
  .mobile-bank-card-header{
    background-color: #F3F3F3;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
.mt-minus-3{
  margin-top: -3px;
}
.bank-delete-no-style{
  width: 151px;
  height: 40px;
  color: #9F1810;
  background-color: #ffff;
  border: 1px solid #9F1810
}
.bank-delete-yes-style{
  width: 151px;
  height: 40px;
}
.upload-placeholder-color{
  color: #111313!important;
}
  @media screen and (min-width:1023px){
    .timerModalHeight {
      height: 260px;
    }
    .success-faild-icon{
      width: 56px;
      height: 56px;
    }
    .grid-container {
      display: grid;
      column-gap: 1%;
      row-gap: 16px;
      grid-template-columns: 30% 33% 32%;
    }
    .grid-title{
      font-size: 18px;
      line-height: 21px;
      color: #111313;
    }
    .grid-Subtitle{
      font-size: 16px;
      line-height: 18px;
    }
  }