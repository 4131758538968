.bgDiv {
  background: #dfece9;
  border-radius: 10px;
  height: 8px;
}

.completedDiv {
  background: #127960;
  border-radius: 10px;
  height: 8px;
  position: absolute;
  top: 0;
}
.width-fit-content {
  width: fit-content;
}
.borderRadiusTopLeftBottomRight8 {
  border-radius: 8px 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -16px;
}
.colorAccent1 {
  color: #051e18;
}
.CertifiedBack {
  background-color: #dfece9;
}
.WRFCSS {
  background-color: #fbe1b2;
  padding-top: 6px;
  padding-bottom: 6px;
}
.wrfprocessing {
  background-color: #dfece9;
  padding-top: 8px;
  padding-bottom: 8px;
}
.loanAmtBorder {
  background: #5ab49e;
}
.rejectPopUpCSS {
  color: #9f1810;
}
.padding-bottom2 {
  padding-bottom: 2px;
}
@media screen and (min-width: 1023px) {
  .loanAmtBorder {
    padding-left: 16px;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }
  .buttonCSS {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    width: 260px;
  }
  .WRFCSS {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
