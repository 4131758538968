.def-style {
  width: 100%;
  height: 101px;
  background: #dfece9;
  border-radius: 8px;
  display: block;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  align-items: center;
  padding-bottom: 26px;
  margin: 0;
}

.margin-bottom12 {
  margin-bottom: 12px;
}

.txt-uppercase {
  text-transform: uppercase;
}
