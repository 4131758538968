.custom-radio {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  /* background-color: #5C6B25; */
  /* Not removed via appearance */
  margin: 0;
  /* background-color:transparent; */
  font: inherit;
  color: #179778;
  width: 22px;
  height: 22px;
  border: 1px solid #111313;
  border-radius: 50%;
  transform: translateY(-0.075em);
  /* background-color:white; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.bigger {
  width: 30px;
  height: 30px;
}
.tds-radio {
  height: 16px !important;
  width: 16px !important;
}

.custom-radio::before {
  content: "";
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #179778;
}

.custom-radio:checked::before {
  transform: scale(1.2);
}

.custom-radio-span {
  display: inline-block;
}

.tds-radio::before {
  width: 16px !important;
  height: 16px !important;

  border: 1.5px solid #179778 !important;
}
.tds-radio:checked {
  border: 1.5px solid #179778 !important;
}
.tds-radio:checked::before {
  transform: scale(0.6) !important;
}
.custom-radio:disabled {
  background-color: rgb(220, 220, 220);
}
