.billingdiV {
    border: 1px solid #e1e3e3;
    /* backdrop-filter: blur(2px); */
    padding: 21px 16px 25px 16px;
    margin-top: 20px;
    border-radius: 8px;
  }
  .inputBorder {
    border: 1px solid #e1e3e3 !important;
  }
  .mobBillingDiv {
    border: 1px solid #e1e3e3;
    /* backdrop-filter: blur(2px); */
    padding: 7px 8px 7px 8px;
    margin-top: 16px;
    border-radius: 8px;
  }
  .mob-Billing-style {
    border: 1px solid #e1e3e3;
    /* backdrop-filter: blur(2px); */
    padding: 10px;
    border-radius: 8px;
  }
  .userdiV {
    border: 1px solid #e1e3e3;
    padding: 17px 14px 23px 14px;
    margin-top: 40px;
  }
  .colorLightBlack {
    color: #404545;
  }
  .marginBottom22 {
    margin-bottom: 22px;
  }
  .marginBottom30 {
    margin-bottom: 30px;
  }
  .h-40{
    height: 40px;
  }
  .lineCss2 {
    border: 2px solid #d9d9d9;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .moblineCss2 {
    border: 2px solid #d9d9d9;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .contactPersonContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  .contactPersonContainer > div:nth-child(5) {
    grid-column: span 2;
  }
  
  .userContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  .userContainer > div:nth-child(3) {
    grid-column: span 2;
  }
  
  .marginb0 {
    margin-bottom: 0px !important;
  }
  .addButton {
    width: 177px;
    padding: 11px 20px 11px 20px;
    border-radius: 6px;
    background: #0e5b48;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    margin-top: 16px;
  }
  
  .mobAddButton {
    width: 100%;
    padding: 11px 20px 11px 20px;
    border-radius: 6px;
    background: #0e5b48;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    margin-top: 24px;
  }
  
  .grayButton {
    background-color: #b8bdbd;
  }
  .contactTable {
    grid-column: 1 / -1;
  }
  
  .tableClassName {
    display: flex;
    flex-direction: column;
  
    border-collapse: collapse;
  
    scrollbar-width: thin;
  }
  .actionsColStyle {
    width: max-content;
    min-width: 100px;
    flex: 0 !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radioBtn {
    height: 16px !important;
    width: 16px !important;
  }
  .marginTop32 {
    margin-top: 32px;
  }
  .primaryLabel {
    background: #127960;
    border-radius: 10px;
    color: white;
    padding: 3px 7px;
    margin-left: 16px;
    font-size: 8px;
  }
  .actionDropDown {
    position: absolute;
    top: 0;
    left: 0;
  }
  .setButton {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: 0.005em;
    color: #7c878b;
  }
  .backWhite {
    background-color: white;
  }
  .dot {
    width: 3px;
    height: 3px;
    background-color: #626262;
    border-radius: 50%;
    margin-top: 2px;
  }

  .mt-2{
    margin-top:2px!important;
  }