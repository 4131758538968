.img-top2 {
  margin-top: -2px;
}

.img-top4 {
  margin-top: -4px;
}

.cta-btn {
  border-radius: 6px;
  padding: 12px 0;
  width: 210px;
  font-weight: 500;
  color: #fff;
}

.backYellow {
  background: #e89c0d;
}

.can-common {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 40px;
}
