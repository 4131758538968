.container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
}

.row {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.value {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.barContainer {
  height: 12px;
  margin-top: 5px;
}

.fullBar {
  position: relative;
  width: 100%;
  height: 100%;

  background: #b7d2cc;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #b7d2cc;
}
.limitBar {
  position: absolute;
  height: 100%;
  top: 0;
}

.deskstpBar {
  background-color: #1a5336;
  left: 0;
}
.mobstpBar {
  background-color: #115846;
  left: 0;
}

.deskltpBar {
  background-color: #4caf50;
}
.mobltpBar {
  background-color: #f9ba10;
}

.utilizedBar {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #7fba7a;
  border-radius: 10px;
}
.height5 {
  height: 5px !important;
}
