.loanBox {
  padding: 16px;
  border-radius: 8px;
  background: #f0fbf8;
  padding: 12px 16px;
}
.width70 {
  width: 70%;
}
.progress-bar {
  height: 10px;
  border-radius: 8px;
}
.lineCss {
  border: 1px solid #0e5b4833;
  height: 88px;
}
