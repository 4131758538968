.custom-radiobtn {
  position: relative;
  margin: 0;
  height: 16px;
}
.custom-radiobtn > span {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1.5px solid #111313;
}
.custom-radiobtn input {
  appearance: none;
  display: none;
}

.custom-radiobtn input:checked + span {
  content: " ";
  width: 16px;
  height: 16px;

  border-radius: 50%;
  border: 1.5px solid #179778;
}
.custom-radiobtn input:checked + span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  content: " ";
  width: 9.6px;
  height: 9.6px;

  border-radius: 50%;
  background-color: #179778;
}
.custom-radiobtn input:disabled + span {
  content: " ";
  width: 16px;
  height: 16px;

  border: 1px solid #b8bdbd;
}
.custom-radiobtn input:checked:disabled + span {
  content: " ";
  width: 16px;
  height: 16px;
  background-image: url("../../Images/CommonImages/custom-chkbox-tick.svg");
  background-color: #677373;

  border: 1px solid #b8bdbd;
  background-repeat: no-repeat;
  background-position: 48% 50%;
}
