.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0e5b48;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
}

.leftTitle {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.rightNumber {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
