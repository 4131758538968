.stickyFooter {
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 99;
  -webkit-box-shadow: 0px 0px 10px rgb(0 0 0 / 11%);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 11%);
}
.justify-content-around {
  justify-content: space-around;
}

/* .sticky-footer-height {
  height: 56px;
} */

.backToTopCSS {
  border: 1px solid #179778;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 7px;
  padding-bottom: 7px;
  position: absolute;
  bottom: 66px;
}
.IconHeightWidth {
  height: 24px;
  width: 24px;
}
.border-radius50 {
  border-radius: 50%;
}

.profileBack {
  background-color: #e8f5f1;
}

.marginTop60 {
  margin-top: 60px;
}
