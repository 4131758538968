.countBox {
  width: 30px;
  height: 26px;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0e5b48;
}
