.breadcrumbCSS {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 100px;
  list-style: none;
  background-color: #dfece9;
}
.breadcrumbLiCSS {
  padding-left: 4px;
  color: #656e71;
}
.breadcrumbLiActive {
  color: #192323 !important;
  font-weight: 500;
}
.breadcrumbLiCSS + .breadcrumbLiCSS::before {
  float: left;
  padding-right: 4px;
  color: #656e71;
  content: "/";
}
.ColorCSS {
  color: #656e71;
}
