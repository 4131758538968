.loader-container {
  position: fixed;
  min-height: 90px;
  padding: 16px 0px;
  width: 70%;
  max-width: 300px;
  top: 50%;
  left: 50%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 10%);
  border-radius: 8px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1052;
  transform: translate(-50%, -50%);
}

.backdrop {
  opacity: 0.5;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1051;
  width: 100vw;
  height: 100vh;
}

.overflowHidden {
  overflow: hidden !important;
}
.loader-text-color {
  color: #677373;
}

.blur {
  filter: blur(2px);
  width: 100vw;
  height: 100vh;
}
