.closedForm {
  width: 153px;
  height: 39px;
  border-radius: 8px 8px 0px 0px;
  background: #0e5b48;
}

.openForm {
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 15.8px 0px #00000040;
  background-color: white;
  width: 100%;
  z-index: 10000;
}
.sendButton {
  width: 133px;
  height: 40px;
  padding: 12px;

  border-radius: 6px;

  background: #0e5b48;
}
.input-style-disable {
  background: #eeeeee !important;
}

.input-style {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-radius: 4px;
  padding: 4px 8px;
  width: 100%;
  font-size: 14px;
  height: 40px;
  line-height: 16px;
}
.getInTouch {
  border-radius: 8px 8px 0px 0px;
  background: #0e5b48;
  height: 46px;
}
.input-title1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 4px;
  letter-spacing: 0.0025em;
  color: #7c878b;
}
.textarea-style {
  height: 104px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;
  padding: 12px;
  outline: none;
}
.positionAbsolute {
  position: absolute;
  top: -39px;
  right: 30px;
}

.positionMobFixed {
  position: fixed;
  bottom: 80px;
  right: 10px;
}

.width400 {
  width: 395px;
}
.openFormAbsolute {
  position: absolute;
  right: 30px;
  top: -658px;
}

.openFormAbsoluteWithoutOther {
  position: absolute;
  right: 30px;
  top: -506px;
}

.openMobFormFixed {
  position: fixed;
  bottom: 0px;
}

.whiteBackground {
  background-color: white;
}

.redBorder {
  border: 1px solid red;
}
.overFLowscroll {
  max-height: 460px;
  overflow-y: scroll;
  scrollbar-width: thin;
}
