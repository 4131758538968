.progress-tab {
  padding: 16px;
  height: 84px;
  left: 0px;
  position: sticky;
  top: 58px;
  z-index: 2;
  background: #ffffff;
  border-bottom: 1px solid #c9c9c9;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
}

.step-number {
  width: 24px;
  height: 24px;

  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  letter-spacing: 0.005em;
  background: #eeeeee;
  color: #7c878b;
}

.num-completed {
  border: 1px solid #127960;
  background: #127960;
  color: #ffffff;
}

.num-current {
  border: 1px solid #127960;
  color: #127960;
  background-color: #ffffff;
}
.step-text {
  width: 50px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #666760;
  margin: 0 auto;
  margin-top: 4px;
}
.completed-text {
  color: #127960;
}

.flex1 {
  flex: 1;
}
.line {
  position: absolute;
  width: 50%;
  height: 1px;
  top: 11px;
  z-index: -1;
  background-color: #e2e2e2;
}

.before {
  left: 0;
}

.after {
  right: 0;
}

.completed {
  background-color: #127960;
}

@media only screen and (min-width: 1024px) {
  .progress-tab {
    padding: 20px;
  }
  .step-text {
    width: auto;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.005em;
  }
  .width-40 {
    width: 40%;
  }
}
