.inputBorder {
  border: 1px solid #e1e3e3 !important;
}
.color333333 {
  color: #333333;
}
.to-verify-email-text {
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #269940;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.to-verify-resend-email-text {
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #2081c3 !important;
}
.verify-loader-style {
  top: 14px !important;
}
.disable-send-link {
  margin-top: 10px;
  color: #7c878b !important;
}
