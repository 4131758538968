.backgroundBox {
  border-radius: 8px;
  border: 1px solid #dadada;
  position: relative;
  padding: 16px;
}

.titleBox {
  padding: 6px 20px;
  top: -32px;
  min-width: 120px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #d9d9d9;
  color: #0e5b48;
}
