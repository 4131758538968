.def-error-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.004em;
  color: #d42015;
}

.margin-top4 {
  margin-top: 4px;
}

@media screen and (min-width: 1023px) {
  .def-error-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #d42015;
  }
}

.width12 {
  width: 12px;
}

.width16 {
  width: 16px;
}
