.Farmer_Background {
  background: rgba(255, 188, 91, 0.2);
}
.Farmer_Height {
  max-height: 74px;
  max-width: 70px;
}
.Trader_Background {
  background: rgba(85, 165, 64, 0.2);
}
.Trader_Height {
  max-height: 60px;
  max-width: 140px;
}
.errorCSS {
  color: #d42015;
}
.FPO_Background {
  background: rgba(132, 170, 149, 0.2);
}
.FPO_Height {
  max-height: 74px;
  max-width: 125px;
}
.Banks_Background {
  background: rgba(75, 191, 235, 0.2);
}
.Banks_Height {
  max-height: 64px;
  max-width: 64px;
}
.Miller_Background {
  background: rgba(237, 69, 105, 0.2);
}
.Miller_Height {
  max-height: 70px;
  max-width: 70px;
}
.Broker_Background {
  background: rgba(113, 110, 69, 0.2);
}
.Broker_Height {
  max-height: 87px;
  max-width: 84px;
}
.Corporate_Background {
  background: rgba(110, 186, 237, 0.2);
}
.Corporate_Height {
  max-height: 70px;
  max-width: 70px;
}
.padding-left6 {
  padding-left: 6px;
}
.padding-right6 {
  padding-right: 6px;
}
.Aadhar_Background {
  background: #fdf0d9;
}
.Pan_Background {
  background: #eeeeee;
}
.Gst_Background {
  background: #eeeeee;
}
.Aadhar_Height {
  max-height: 51px;
  max-width: 79px;
  margin-top: 10.35px;
  margin-left: 8.41px;
  margin-right: 9.05px;
  /* margin-bottom: 6.6px; */
}
.Pan_Height {
  max-height: 46px;
  max-width: 73px;
  margin-top: 9px;
  margin-left: 12px;
  margin-right: 12.27px;
  margin-bottom: 6.6px;
}
.Gst_Height {
  max-height: 37px;
  max-width: 79px;
  margin-top: 13px;
  margin-left: 9px;
  margin-right: 9.27px;
  margin-bottom: 11.6px;
}
.flex45 {
  flex: 45%;
  max-width: 47%;
}

.Height_95 {
  height: 95px;
}

.margin-bottom14 {
  margin-bottom: 14px;
}
.selectedBorder {
  border: 1px solid #127960;
}
.skipColor {
  color: #bbbaba;
}
.stickyFooter {
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 99;
}
.txt-uppercase {
  text-transform: uppercase;
}
.card-gradient {
  background: linear-gradient(294.04deg, #e1e3e3 1.44%, #fdf0d9 93.06%);
}
.iconHeightWidth16 {
  height: 16px;
  width: 16px;
}
.Height_83 {
  height: 83px;
  width: 95px;
}
.inputBorder {
  border: 1px solid #e1e3e3 !important;
}
.color333333 {
  color: #333333;
}
.input-file-upload::-webkit-file-upload-button {
  visibility: hidden;
}

.input-file-upload {
  display: inline-block;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10px;
}
.fileIconPos {
  top: 6px;
  left: 12px;
}
.fileUploadIconPos {
  top: 6px;
  right: 12px;
}
.height-80 {
  height: 80px;
}
.boxRadius40 {
  border-radius: 40px;
}
.sug_back {
  background: #fdf0d9;
}
.sel_back {
  background: #f8d38c;
}
.padding-bottom10 {
  padding-bottom: 10px;
}
.lightGrey {
  color: #b3b3b3;
}
.skipCSS {
  border: 1px solid #9f1810 !important;
  color: #9f1810;
}
.padding-bottom85 {
  padding-bottom: 85px;
}
.margin-left20 {
  margin-left: 20px;
}
.input-border {
  border: 1px solid #e1e3e3 !important;
}
.textColorBlack > div > div > div {
  color: black !important;
}
.margin-top4px {
  margin-top: -4px !important;
}
.margin-top-neg-12 {
  margin-top: -12px;
}

.edit-commodity > div {
  margin: 0px !important;
  height: 100%;
  /* background-color: rebeccapurple; */
  max-width: unset !important;
}
.edit-commodity > div > div {
  border: none !important;
  height: 100%;
  border-radius: 0px !important;
}
/* .registrationPopUP .modal-dialog {
  height: 100%;
}
.registrationPopUP .modal-dialog .modal-content {
  height: 100%;
} */
.text-truncate-file-upload {
  max-width: calc(100vw - 112px);
  display: block !important;

  /* text-overflow: ellipsis; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 1; */
  /* /* -webkit-box-orient: vertical; */
}
.progressCSS {
  position: relative !important;
  top: unset !important;
}
.blurBackGround {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
/* .backgroundWhite > div {
  max-width: unset !important;
} */

.Height_121 {
  height: 121px;
}
.flex22 {
  flex: 22.5%;
  max-width: 23%;
}
.to-verify-email-text {
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #0e5b48;
}
.desk-radiobtn {
  display: flex;
}
.desk-radio-btn-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.005em;
  color: #656e71;
}
.input-verified {
  background: #d4f4db;
  /* Alert/Success/4 */

  border: 1px solid #269940 !important;
  border-radius: 6px;
}
.verify-loader-style {
  top: 13px !important;
}
.error-icon-style {
  margin-top: 2px;
}
.positionEyeCSS {
  right: 8px;
  top: 50%;
  z-index: 10;
}
@media only screen and (min-width: 1024px) {
  .Farmer_Height {
    max-height: 84px;
    max-width: 84px;
  }
  .Trader_Height {
    max-height: 68px;
    max-width: 157px;
  }
  .FPO_Height {
    max-height: 96px;
    max-width: 145px;
  }
  .Broker_Height {
    max-height: 110px;
    max-width: 107px;
    margin-top: 10px !important;
  }

  .Corporate_Height {
    max-height: 87px;
    max-width: 87px;
  }
  .Banks_Height {
    max-height: 88px;
    max-width: 88px;
  }

  .Miller_Height {
    max-height: 86px;
    max-width: 86px;
  }
  .Pan_Height {
    max-height: 50px;
    max-width: 80px;
    margin-top: 12px;
    margin-left: 31px;
    margin-right: 31px;
    margin-bottom: 9px;
  }
  .Gst_Height {
    max-height: 54px;
    max-width: 115px;
    margin-top: 10px;
    margin-left: 13px;
    margin-right: 14px;
    margin-bottom: 7px;
  }
  .Aadhar_Height {
    max-height: 67px;
    max-width: 105px;
    margin-top: 8px;
    margin-left: 18px;
    margin-right: 19px;
    /* margin-bottom: 6.6px; */
  }
  .iconHeightWidth16 {
    height: 24px;
    width: 24px;
  }
  .height43Imp > div {
    min-height: 43px !important;
  }
  .height43Imp > div > div > div {
    font-size: 16px;
    line-height: 19px;
  }
  .width235 {
    width: 23.5%;
  }
  .desk-input-error-style {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #d42015 !important;
  }
  .desk-info-error-icon {
    width: 18px;
    height: 18px;
    margin-top: -2px;
  }
  .positionEyeCSS {
    top: 17px;
  }
}
.padding-left20 {
  padding-left: 20px;
}
.width18px {
  width: 18px;
}
.desktopCssImp {
  padding-top: unset !important;
  font-size: 16px !important;
  line-height: 19px !important;
  font-weight: 400 !important;
}
.width-273 {
  width: 273px;
}
.padding105 {
  padding: 10.5px;
}
.height43 {
  height: 43px;
}

.pox-password {
  -webkit-text-security: disc;
  -moz-webkit-text-security: disc;
  -moz-text-security: disc;
}
.lineCss {
  border: 2px solid #d9d9d9;
  margin-top: 40px;
  margin-bottom: 40px;
}

.msme-desk-buttons {
  border-radius: 24px;
  width: 190px;
  border: 1px solid #e2e2e2;
}
.msme-buttons {
  border-radius: 24px;
  width: 160px;
  border: 1px solid #e2e2e2;
}
.farm-desk-size-style {
  padding: 19px 14px 12.5px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.farm-size-style {
  padding: 12px 13px 12px 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* Billing information CSS */

.billingdiV {
  border: 1px solid #e1e3e3;
  /* backdrop-filter: blur(2px); */
  padding: 21px 16px 25px 16px;
  margin-top: 20px;
}

.mobBillingDiv {
  border: 1px solid #e1e3e3;
  /* backdrop-filter: blur(2px); */
  padding: 7px 8px 7px 8px;
  margin-top: 16px;
}
.userdiV {
  border: 1px solid #e1e3e3;
  padding: 17px 14px 23px 14px;
  margin-top: 40px;
}
.colorLightBlack {
  color: #404545;
}
.marginBottom22 {
  margin-bottom: 22px;
}
.marginBottom30 {
  margin-bottom: 30px;
}
.lineCss2 {
  border: 2px solid #d9d9d9;
  margin-top: 30px;
  margin-bottom: 30px;
}
.moblineCss2 {
  border: 2px solid #d9d9d9;
  margin-top: 24px;
  margin-bottom: 24px;
}
.contactPersonContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.contactPersonContainer > div:nth-child(5) {
  grid-column: span 2;
}

.userContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.userContainer > div:nth-child(3) {
  grid-column: span 2;
}

.marginb0 {
  margin-bottom: 0px !important;
}
.addButton {
  width: 177px;
  padding: 11px 20px 11px 20px;
  border-radius: 6px;
  background: #0e5b48;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  letter-spacing: 0.005em;
  text-align: center;
  color: white;
  margin-top: 16px;
}

.mobAddButton {
  min-width: 100%;
  padding: 11px 20px 11px 20px;
  border-radius: 6px;
  background: #0e5b48;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  letter-spacing: 0.005em;
  text-align: center;
  color: white;
  margin-top: 16px;
}

.grayButton {
  background-color: #b8bdbd;
}
.contactTable {
  grid-column: 1 / -1;
}

.tableClassName {
  display: flex;
  flex-direction: column;

  border-collapse: collapse;

  scrollbar-width: thin;
}
.actionsColStyle {
  width: max-content;
  min-width: 100px;
  flex: 0 !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioBtn {
  height: 16px !important;
  width: 16px !important;
}
.marginTop32 {
  margin-top: 32px;
}
.primaryLabel {
  background: #127960;
  border-radius: 10px;
  color: white;
  padding: 3px 7px;
  margin-left: 16px;
}
.dot {
  width: 3px;
  height: 3px;
  background-color: #626262;
  border-radius: 50%;
  margin-top: 2px;
}
.actionDropDown {
  position: absolute;
  top: 0;
  left: 0;
}
.setButton {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.005em;
  color: #7c878b;
}
.backWhite {
  background-color: white;
}
.mb2 {
  margin-bottom: 2px;
}
.mtmin30 {
  margin-top: -30px;
}
.z100{
  z-index: 100;
}
