.header-height {
  height: 96px;
}
.desk-min-content-height {
  min-height: 500px !important;
}
.box-shadow-unset {
  box-shadow: unset;
}
.margin-bottom24 {
  margin-bottom: 24px;
}
.wid400 {
  width: 400px;
}
.margin-bottom21 {
  margin-bottom: 21px;
}
.margin-bottom29 {
  margin-bottom: 29px;
}
.input-style {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
}
.manage-notify-card-style {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.input-style-disable {
  background: #f5f5f5;
}

.input-style-error {
  border: 1px solid #d42015;
}
.input-error-style {
  margin-top: 6px;
  padding-top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.004em;

  /* Alert/Danger/4 */

  color: #d42015;
}

.input-title1 {
  height: 14px;

  /* Body 2/Semibold */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  letter-spacing: 0.0025em;
  color: #7c878b;
}
.disable-send-link {
  color: #7c878b;
}
.input-verified {
  /* Accent/9 */

  background: #dfece9;
  /* Alert/Success/4 */

  border: 1px solid #269940;
  border-radius: 6px;
}
.input-email-verified {
  /* Accent/9 */
  /* Alert/Success/4 */

  border: 1px solid #269940 !important;
  border-radius: 6px !important;
}

.cov-pic-modal-padding {
  padding: 12px 16px 24px 16px;
}

.border-radius6 {
  border-radius: 6px;
}
.bg-dark-6 {
  background-color: #0e5b48;
}
.button-margin {
  margin: 12px 0;
}

.card-style {
  background: #ffffff;
  /* Text/Light/3 */

  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 12px;
}

.card-style {
  border: 1px solid #e1e3e3;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px #0000001a;
  /* min-width: 340px; */
  min-height: 385px;
  padding: 16px;
}

.title2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.0025em;

  /* Text/Dark/9 */
  margin-bottom: 6px;
  color: #7c878b;
}

.margin-bottom6 {
  margin-bottom: 6px;
}

.backgrey {
  background-color: #8e9797;
}

.margin-bottom84 {
  margin-bottom: 84px;
}

.verify-otp-loader {
  margin-left: 4px;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* margin: -25px 0 0 -25px; */
  width: 19px;
  height: 19px;
}
.btn-style {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 0.0125em;

  color: #4d5557;

  background: #e2e2e2;
  border-radius: 4px;

  width: 100%;
  display: block;
  padding: 12px 0px;
}
.btn-style {
  background-color: #00a789;
  border: 1px solid #00a789;
  border-radius: 3px;
  color: white;
  font-size: min(15px, 16px);
}

.btn-style:disabled {
  /* background: grey;*/
  border: unset;
  color: #4d5557;

  background: #e2e2e2;
  /* background: #00A789;
    opacity: 0.2;
    border: 1px solid #00A789;
    border-radius: 2px;
    color: #000000; */
}
.manage-notify-card-style {
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}

.custom-radio-buttom-disable {
  background-color: #fff !important;
}
.notify-card-hr-style {
  border-bottom: 1px solid #e1e1e1;
}
@media only screen and (min-width: 1024px) {
  .notify-card-hr-style {
    border-bottom: 1px solid #e1e1e1;
    margin-left: -16px;
    margin-right: -16px;
  }
  .input-title1 {
    height: 14px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.005em;
  }

  .input-title-1 {
    height: 14px;
    /* font-size: 14px; */
    line-height: 16px;
    letter-spacing: 0.005em;
  }

  .input-style {
    height: 43px;
    padding: 12px;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
  }
  .verify-check-icon {
    top: 36% !important;
  }
}

.imp-mb24 {
  margin-bottom: 24px !important;
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
}
.list-card-style {
  box-shadow: 0px 4px 10px 0px #0000001a;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}
/* background: #293A3A; */
.inputBorder {
  border: 1px solid #eeeeee !important;
}
.commodity-preference-input-style {
  height: 40px;
}
.lang-selected-option {
  background: #0e5b48;
  color: white;
}

.lang-not-selected-option {
  background: #dfece9;
  color: #0e5b48;
}
.marginY-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.prefered-commodity-cta {
  margin-top: 40px;
}

/* .colHeight {
  min-height: 464px;
} */

.side-nav-title {
  color: #191d23;
}
.side-nav-image {
  width: 24px;
  height: 24px;
}

.ht24 {
  height: 24px;
}

.wid24 {
  width: 24px;
}

.wid18 {
  width: 18px;
}

.ht28 {
  height: 28px;
}

.wid28 {
  width: 28px;
}

.wid183 {
  width: 183px;
}

.wid360 {
  width: 360px;
}

.table-borderRadius {
  border-radius: 8px;
  overflow: hidden;
}

.backLightGrey3 {
  background: #f3f3f3;
}

.input-title-1 {
  height: 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  /* font-size: 12px; */
  line-height: 14px;
  margin-bottom: 4px;
  /* identical to box height */
  letter-spacing: 0.0025em;
  color: #7c878b;
}

/* .input-style-disable {
  background: #eeeeee;
} */

.desk-cu-react-select {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  letter-spacing: 0.005em;
  width: 100%;
}
.desk-cu-react-select::placeholder {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #b8bdbd;
}

.title-2-line {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  color: #1f2223;
}

.subtitle-2-line {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  color: #7c878b;
}

.desktopMenuDiv {
  position: absolute;
  width: 114px;
  background-color: white;
  padding: 16px 0px 0px 12px;
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  top: 26px;
  right: 0;
  z-index: 100;
}

.desktopMenuOptionDiv {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hr-divider {
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
  position: absolute;
  top: 0;
  right: 0;
}

.height43Imp > div {
  min-height: 43px !important;
}

.errorCSS {
  color: #d42015;
}

.error-icon-style {
  vertical-align: bottom;
  margin-right: 5.5px;
}

.va-amiddle {
  vertical-align: middle !important;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-15 {
  width: 15%;
}

.width-70 {
  width: 70%;
}

.wid400 {
  width: 400px;
}

.backTextLight9 {
  background-color: white;
}

.colWidth {
  max-width: 340px;
}

/* @media only screen and (min-width: 1350px) {
  .colWidth {
    min-width: 340px;
  }

  .colHeight {
    height: fit-content;
  }
} */

.asterisk {
  color: #d5261b;
  margin-top: -5px;
}

.wid400 {
  width: 400px;
}

.cta-btn {
  /* background: #0e5b48; */
  border-radius: 6px;
  padding: 9px 0;
  width: 100%;
  font-weight: 500;
  color: #fff;
}

.col2Width {
  flex: 1 0 66.666667%;
  position: relative;
  width: 100%;
}
.header-style {
  height: 64px;
}
.back-arrow {
  height: 24px;
  width: 24px;
}

.hr-line {
  margin-top: 32px;
  margin-bottom: 32px;
  border-top: 2px solid #d9d9d9;
}

.add-gst-btn-style {
  width: 181px;
  height: 40px;
  padding: 10.5px 0;
}
.add-gst-header-text-style {
  color: #404545;
}
.gst-detail-style {
  border-bottom: 2px solid #d9d9d9;
}
.mt-32 {
  margin-top: 32px;
}
.gst-pill {
  width: 115px;
  min-width: 110px;
  height: 42px;
  border-radius: 3px 3px 0px 0px;
  padding: 6px;
}
.gst-pill-unselect {
  color: #666666;
}
.pill-style {
  overflow-x: scroll;
}
.mt-48 {
  margin-top: 48px;
}
