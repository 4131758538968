.padding6 {
  padding: 6px;
}
.radius50 {
  border-radius: 50%;
}
.border-bottom {
  border-bottom: 1px solid #e1e3e3;
}
.positionTopBottom {
  bottom: 0;
  left: 0;
}
.imageHeightWidth {
  height: 206px;
}
.backDrop {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 8px 8px;
}
