.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrapper > div:nth-child(2n) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 40%;
  margin-bottom: 24px;
}
.wrapper > div:nth-child(2n + 1) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 45%;
}
.two-line-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
  color: #111313;
}
.two-line-subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  color: #7c878b;
}
.desk-two-line-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.001em;
  color: #111313;
}
.desk-two-line-subtitle {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #7c878b;
}
.desk-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.desk-wrapper > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30%;
}
.desk-wrapper > div:nth-child(1) {
  margin-bottom: 26px;
}
.desk-wrapper > div:last-child {
  margin-bottom: 24px;
}
.grid-container {
  display: grid;
  grid-template-columns: 40% 40%;
}
.grid-container > div {
  margin-bottom: 24px;
}
.grid-container > div:last-child {
  margin-bottom: 0px;
}
