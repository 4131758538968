.blur-root {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
}
@media only screen and (min-width: 1024px) {
  .blur-root {
    filter: blur(7px);
    -webkit-filter: blur(7px);
    backdrop-filter: blur(7px);
  }
}

.padding-top20 {
  padding-top: 20px;
}

.blur-modal {
  padding: 0;
}
.blur-modal > :global(div.modal-dialog) {
  margin: 0;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.blur-modal > :global(div.modal-dialog) > :global(div.modal-content) {
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  box-shadow: 4px -4px 14px rgba(0, 0, 0, 0.25);
}

.blur-modal
  > :global(div.modal-dialog)
  > :global(div.modal-content)
  > :global(div.modal-body) {
  padding: 0;
}

.blur-modal
  > :global(div.modal-dialog)
  > :global(div.modal-content)
  > :global(div.modal-body)
  > hr {
  margin: 0;
}

.blur-modal
  > :global(div.modal-dialog)
  > :global(div.modal-content)
  > :global(.pill) {
  position: absolute;
  width: 64px;
  height: 4px;
  background: #b8bdbd;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%, 4px);
}

.blur-modal
  > :global(div.modal-dialog)
  > :global(div.modal-content)
  > :global(span.close) {
  position: absolute;
  right: 18px;
  top: -30px;
  font-family: revert;
  color: white;
  font-weight: 200;
  border: none;
  opacity: 0.7;
  line-height: 1;
  text-shadow: none;
}

/* fading animation */
.blur-modal:global(.modal.fade) :global(.modal-dialog) {
  transform: translate(0, 100%);
}
.blur-modal:global(.modal.show) :global(.modal-dialog) {
  transform: translate(0, 0%);
}

.custom-backdrop {
  background: rgba(34, 34, 34, 0.4);
}
