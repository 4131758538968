.aryaCertifiedCSS {
  background: #dfece9;
  border-radius: 8px 0px 8px 0px;
  width: fit-content;
  padding: 5px 8px;
}

.border-left-rad-not-rouned {
  border-top-left-radius: 0px;
}
