.userdiV {
    border: 1px solid #e1e3e3;
    padding: 17px 14px 23px 14px;
    margin-top: 40px;
  }
  .inputBorder {
    border: 1px solid #e1e3e3 !important;
  }  .addButton {
    width: 177px;
    padding: 11px 20px 11px 20px;
    border-radius: 6px;
    background: #0e5b48;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    margin-top: 16px;
  }
  .userContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  .userContainer > div:nth-child(3) {
    grid-column: span 2;
  }
  .marginb0 {
    margin-bottom: 0px !important;
  }
  .addButton {
    width: 177px;
    padding: 11px 20px 11px 20px;
    border-radius: 6px;
    background: #0e5b48;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    margin-top: 16px;
  }
  
  .mobAddButton {
    min-width: 100%;
    padding: 11px 20px 11px 20px;
    border-radius: 6px;
    background: #0e5b48;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    letter-spacing: 0.005em;
    text-align: center;
    color: white;
    margin-top: 16px;
  }
  
  .grayButton {
    background-color: #b8bdbd;
  }
  .contactTable {
    grid-column: 1 / -1;
  }

  .color404545{
    color:#404545;

  }
  .input-style-disable {
    background: #eeeeee;
  }
  
