.card-style {
  position: absolute;
  top: 4vh;
  z-index: 2;
  right: 0vw;
  width: 194px;
  background: #ffffff;
  border: 1px solid #e1e3e3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.card-title {
  height: 35px;
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #4d5557;
}
.card-options {
  display: flex;
  align-items: center;
  padding: 8px 0px;
}
.card-options:hover {
  background: #dfece9;
  margin-left: -12px;
  padding-left: 12px;
  /* position: absolute;
  background: #dfece9;
  width: 100%;
  height: 45px;
  padding-left: 12px;
  margin-left: -12px;
  margin-right: -12px; */
  /* margin-bottom: 8px; */
}
.activeOption {
  background: #dfece9;
  margin-left: -12px;
  padding-left: 12px;
}

.w-card-style {
  position: absolute;
  top: 52px;
  z-index: 2;
  right: 0vw;
  width: 194px;
  background: #ffffff;
  border: 1px solid #e1e3e3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
