.card-style {
  min-height: 385px;
  padding: 16px;
}

.card-default {
  border: 1px solid #e1e3e3;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px #0000001a;
}
.card-item-style {
  height: 48px;
}
.selected-bg {
  background: #dfece9;
  border-radius: 4px;
}
.unSelected-bg {
  background: #fff;
}
/* @media screen and (min-width: 1600px) {
  .card-style {
    max-width: 400px;
    min-height: 385px;
  }
}    */
