.outerbox {
  height: 40px;
  background: #ffffff;
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  padding: 12px 16px 12px 16px;
}
.input-file-upload::-webkit-file-upload-button {
  visibility: hidden;
}
.imageNameCSS {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.inputBox {
  border: none !important;
  opacity: 0;
  position: absolute;
}

.disabled {
  background: white;
  border: 1px solid #e1e3e3;
  border-radius: 4px;
}
@media only screen and (min-width: 1024px) {
  .outerbox {
    height: 43px;
    background: #ffffff;
    border: 1px solid #e1e3e3;
    border-radius: 4px;
    padding: 12px 16px 12px 16px;
  }
}
