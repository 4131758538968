.switch {
  position: relative;
  width: 28px;
  height: 16px;
  display: inline-block;
}

.switch > input {
  display: none;
}

.switch > input + label {
  margin: 0;
  position: relative;
  width: 28px;
  height: 16px;
  /* Accent/3 */
  background: #f5f5f5;
  border: 1px solid #0e5b48;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-radius: 0.5rem;
  display: inline-block;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch > input + label::before {
  position: absolute;
  top: 50%;
  bottom: 4px;
  right: 4px;
  background: #0e5b48;
  /* Text/Light/5 */
  width: 8px;
  height: 8px;
  content: " ";
  border-radius: 50%;
  border: 1px solid #0e5b48;
  transform: translate(0, -50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch > input:checked + label {
  background: #0e5b48;
  border: 1px solid #0e5b48;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch > input:checked + label::before {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* Text/Light/5 */
  width: 8px;
  height: 8px;
  content: " ";

  transform: translate(-11px, -50%);
}
