.progress-bar-container {
  width: 100%;
  background: #ffffff;
  border: 0px solid #0e5b48;
}
.heightAndBorder {
  height: 3px;
  border-radius: 4px;
}

.progress-bar-fill {
  height: 100%;
  background: #0e5b48;
}
