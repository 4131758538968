.aryaLogoCSS {
  width: 64px;
  height: 23px;
}
.linearGradient {
  background: linear-gradient(293.5deg, #179778 2.87%, #293a3a 53.65%);
}
.max-width-fit {
  max-width: fit-content;
}
.gap32 {
  gap: 32px;
}
.imgHeightWidth {
  height: 20px;
  width: 20px;
}
.kycTag {
  width: 106px;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 30px;
  padding: 4px;
  border-radius: 4px;
  background: var(--alert-warning-9, #fdf0d9);
}
.margin-top1 {
  margin-top: 1px;
}

.borderWhite {
  border: 1px solid #ffffff;
}
.margin-left2 {
  margin-left: 2px;
}
.IconHeightWidth {
  height: 32px;
  width: 32px;
}
.IconHeightWidth2 {
  height: 20px;
  width: 20px;
}
.border-radius50 {
  border-radius: 50%;
}
.height60 {
  height: 60px;
}
.profileBack {
  background: #dfece9;
  /* Accent/3 */

  border: 1px solid #0e5b48;
}
.profileBack2 {
  background: #e8f5f1;
  /* Accent/3 */
}

.positionFixedHeader {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99;
}
.card-style {
  position: absolute;
  top: 4vh;
  z-index: 2;
  right: 0vw;
  width: 194px;
  background: #ffffff;
  border: 1px solid #e1e3e3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.card-title {
  height: 35px;
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #4d5557;
}
.card-options {
  display: flex;
  align-items: center;
  padding: 8px 0px;
}
.card-options:hover {
  background: #dfece9;
  margin-left: -12px;
  padding-left: 12px;
  /* position: absolute;
  background: #dfece9;
  width: 100%;
  height: 45px;
  padding-left: 12px;
  margin-left: -12px;
  margin-right: -12px; */
  /* margin-bottom: 8px; */
}
.activeOption {
  background: #dfece9;
  margin-left: -12px;
  padding-left: 12px;
}

.w-card-style {
  /* position: absolute;
  top: 40px;
  z-index: 2;
  right: -70px; */
  width: 360px;
  background: #ffffff;
  border: 1px solid #e1e3e3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  /* padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px; */
  max-height: 400px;
  overflow-y: scroll;
}
.w-card-style::-webkit-scrollbar {
  width: 2px;
}
.notificationCSS {
  top: -2px;
  background: #e95545;
  color: white;
  padding: 2px 2px 2px 2px;
  height: 14px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.width12 {
  width: 14px;
  right: 0px;
}
.width21 {
  width: 25px;
  right: -5px;
}
.width16 {
  width: 18px;
  right: -2px;
}
.notification-style {
  position: absolute;
  top: 70px;
  right: 160px;
  z-index: 2;
}

.desktopMenuDiv {
  width: 191px;
  background-color: white;
  padding: 12px 12px 16px 8px;
  border: 1px solid #e1e3e3;
  border-radius: 4px;
  position: absolute;
  right: 10%;
  top: 56px;
  z-index: 100;
}

.desktopMenuOptionDiv {
  display: flex;
  align-items: center;
  padding: 6px;
  cursor: pointer;
}
