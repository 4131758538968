.blur-modal {
  padding: 0 !important;
}
.blur-modal > div.modal-dialog {
  max-height: 80vh;
  margin: 0;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.netBankingPaymentModal {
  display: block;
}
.blur-modal > div.modal-dialog > div.modal-content {
  width: 100vw;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  box-shadow: 4px -4px 14px rgba(0, 0, 0, 0.25);
}

.blur-modal > div.modal-dialog > div.modal-content > div.modal-body {
  padding: 0;
}

.blur-modal > div.modal-dialog > div.modal-content > div.modal-body > hr {
  margin: 0;
}

.blur-modal > div.modal-dialog > div.modal-content > .pill {
  position: absolute;
  width: 64px;
  height: 4px;
  background: #b8bdbd;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%, 4px);
}
.blur-modal > div.modal-dialog > div.modal-content > .dark {
  background: #677373;
}
.blur-modal > div.modal-dialog > div.modal-content > span.close {
  position: absolute;
  right: 18px;
  top: -30px;
  font-family: revert;
  color: white;
  font-weight: 200;
  border: none;
  opacity: 0.7;
  line-height: 1;
  text-shadow: none;
}

/* fading animation */
.blur-modal.modal.fade .modal-dialog {
  transform: translate(0, 100%);
}
.blur-modal.modal.show .modal-dialog {
  transform: translate(0, 0%);
}

.custom-backdrop {
  background: rgba(34, 34, 34, 0.4);
}
.OverThePageModal > div.modal-dialog {
  max-height: 100vh !important;
  display: flex;
  background: white;
  height: 100%;
}
.blur-modal > div.modal-dialog > div.modal-content > div.modal-body {
  max-height: 80vh;
}

.blur-modal .mh60vh {
  max-height: 60vh;
}
/* desktop styles */

@media only screen and (min-width: 1024px) {
  .blur-modal > div.modal-dialog > div.modal-content {
    width: 100%;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .blur-modal {
    left: unset;
    /* 50%; */
    transform: unset;
    /* translate(-25%, 0%); */
  }
  /* .blur-modal.modal.fade .modal-dialog {
    transform: translate(50%, 100%);
  }
  */
  .blur-modal.modal.fade .modal-dialog {
    transform: translate(-50%, 100%);
    top: 50%;
    left: 50%;
  }
  .blur-modal.modal.show .modal-dialog {
    transform: translate(-50%, -50%);
    bottom: unset;
    top: 50%;
    left: 50%;
  }
}

.dheading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #dfece9;
  border-radius: 8px 8px 0px 0px;
  padding: 16px;

  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  letter-spacing: 0.001em;

  /* Text/Dark/5 */

  color: #1f2223;
}

@media only screen and (min-width: 1024px) {
  .blur-modal > div.modal-dialog > div.modal-content > span.close {
    right: 16px;
    top: 16px;
    font-weight: 200;
    border: none;
    opacity: 0.7;
    line-height: 1;
    text-shadow: none;

    background-image: url(../../Images/CommonImages/cross-black-icon.svg);
    background-repeat: no-repeat;
    background-size: 28px;
    width: 28px;
    height: 28px;
  }
  /* .blur-modal > div.modal-dialog > div.modal-content {
    width: 610px;
  } */
}

.history.blur-modal > div.modal-dialog {
  max-width: 610px;
}
.edit-commodity-desktop.blur-modal > div.modal-dialog {
  max-width: 820px !important;
}
