.card {
  border: 1px solid #94b3ac;
  border-radius: 8px;
}

.cardRow {
  display: flex;
  justify-content: space-between;

  align-items: center;
}

.cardRowFontStyle {
  color: #111313;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.001rem;
}

.cardRow:first-child .keyStyle {
  border-radius: 8px 0px 0px 0px;
}

.keyStyle {
  align-self: flex-start;
  background-color: #f0f6ff;
  flex-grow: 1;
  padding: 9px 15px 11px 15px;
  width: 40%;
}
.cardRow:last-child .keyStyle {
  border-radius: 0px 0px 0px 8px;
}
.valueStyle {
  align-self: flex-start;
  flex-grow: 1;
  padding: 9px 15px 11px 15px;
  width: 60%;
  color: #666666;
}

.total {
  margin-right: 16px;
}

.mobileCard {
  margin-top: 16px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  /* width: 100%; */
}
.actionContainer {
  width: 60%;
  padding-left: 16px;
}
