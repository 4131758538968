.img-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 10px;
}

.img {
  top: 0;
  left: 0;
  position: absolute;
  width: 100px;
  height: 100px;
}

.close {
  position: absolute;
  right: 3px;
  background-color: rgb(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 4px;
  top: 3px;
}

.error {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.0025em;

  /* Alert/Danger/4 */

  color: #d42015;
}

.border-radius6 {
  border-radius: 6px;
}
.bg-dark-6 {
  background-color: #0e5b48;
}
.button-margin {
  margin: 12px 0;
}

.cov-pic-modal-padding {
  padding: 12px 16px 24px 16px;
}

.disable-button {
  color: #4d5557;
  background: #e2e2e2;
}

@media only screen and (min-width: 1024px) {
  .cov-pic-modal-padding {
    padding: 24px 16px 20px 16px;
  }
  .error {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.005em;
    /* identical to box height */

    text-align: start;

    /* Alert/Danger/4 */

    color: #d42015;
  }
}

.w90per {
  width: 90%;
}
