.aryaLogoCSS {
  width: 64px;
  height: 23px;
}
.max-width-fit {
  max-width: fit-content;
}
.flex40 {
  flex: 30%;
}
.width-200 {
  width: 200%;
}
.flex20 {
  flex: 23%;
}
.flex40 {
  flex: 40%;
}
.flex27 {
  flex: 27%;
}
.flex33 {
  flex: 33%;
}
.border-left {
  border-left: 1px solid #677373;
  margin-bottom: 19px;
}
.border-top-bottom {
  border-top: 1px solid #677373;
  border-bottom: 1px solid #677373;
}
.paddingtb18 {
  padding-top: 18px;
  padding-bottom: 18px;
}
.colorLightBlue {
  color: #a7d3f1;
}
.align-items-stretch {
  align-items: stretch;
}
.store-icon {
  width: 125px;
  height: 40px;
}
.mr-minus-16 {
  margin-right: -16px;
}
.getInTouch {
  display: flex;
  justify-content: right;
  position: relative;
  bottom: 55px;
}
.getInTouchOpen {
  display: flex;
  justify-content: right;
  position: relative;
  bottom: 550px;
}
