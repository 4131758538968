.container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 7px 17px 0px #00000026;
  margin-bottom: 16px;
}

.commodityTag {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 8px;
  font-weight: bold;
  color: white;
  margin-bottom: 8px;
}

/* Updated background colors for the commodities */
.wheat {
  background-color: #8B344E;
  color: white;
}

.maize {
  background-color: #EEC044;
  color: white;
}

.urad {
  background-color: #0DC7B1;
  color: white;
}

.soyabean {
  background-color: #494949;
  color: white;
}

.cotton {
  background-color: #E59E9E;
  color: white;
}

.rice {
  background-color: #AA6A20;
  color: white;
}

.mustard {
  background-color: #AFC99B;
  color: white;
}

.millets {
  background-color: #D8581C;
  color: white;
}

.gram {
  background-color: #33A2F2;
  color: white;
}

.redgram {
  background-color: #475833;
  color: white;
}

.oats {
  background-color: #82D2DD;
  color: white;
}

.otherCommodity {
  background-color: #0e5b48;
  color: white;
}

.serialNumber {
  color: #030229;
  margin-bottom: 12px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.label {
  color: #666;
  font-size: 0.9em;
}

.value {
  font-weight: bold;
}

.quantityPledged {
  background-color: #e6f3f0;
  color: #0e5b48;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 12px;
}

.quantityPledged span {
  font-weight: bold;
}

.progressBarContainer {
  background-color: #e6e6e6;
  height: 8px;
  border-radius: 4px;
  margin-bottom: 12px;
}

.progressBar {
  background-color: #0e5b48;
  height: 100%;
  border-radius: 4px;
}
