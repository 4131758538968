.back-arrow {
    height: 24px;
    width: 24px;
  }
  .header-style {
    height: 64px;
  }
  .card-style{
    box-shadow: 0px 4px 10px 0px #0000001A;

  }