.width-261px {
  width: 261px;
}

.text-dark-green {
  color: #0e5b48;
}

.card-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.switchTag {
  border-radius: 4px;
  padding: 4px 2px 4px 6px;
  background-color: #ddf1ff;
}

.width-fit-content {
  width: fit-content;
}

.profile-pic-class {
  transform: translate(0, -50%);
}

.mt-n30px {
  margin-top: -30px;
}

.status-bar {
  height: 20px;
  padding: 4px 8px;
  background: #fdf0d9;
  border-radius: 4px;
}

.kyc-pending {
  color: #744e07;
  background: #fdf0d9;
}

.mr-5_5 {
  margin-right: 5.5px;
}
.options-bar {
  height: 84px;
}
.margin-bottom7 {
  margin-bottom: 7px;
}

.text-dark-grey {
  color: #4d5557;
}
.letter-spacing004em {
  letter-spacing: 0.004em;
}
.border-top-light-grey {
  border-top: 1px solid #e1e3e3;
}
.margin-right-13 {
  margin-right: 13px;
}
.paddingY-11 {
  padding: 11px 0;
}
.paddingR21 {
  padding-right: 21px;
}
.paddingL13 {
  padding-left: 13px;
}

.padding-top20 {
  padding-top: 20px;
}

.lang-selected-option {
  background: #0e5b48;
  color: white;
}

.lang-not-selected-option {
  background: #dfece9;
  color: #0e5b48;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-bottom16 {
  margin-bottom: 16px;
}
.marginY-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.bg-grey {
  background: #e2e2e2;
}
.bg-green {
  background: #0e5b48;
}
.border-radius4 {
  border-radius: 4px;
}
.margin-bottom12 {
  margin-bottom: 12px;
}
.paddingY-12 {
  padding: 12px;
}
.text-grey {
  color: #4d5557;
}
.textColorLang {
  color: #293a3a;
}

.margin-top-n31 {
  margin-top: -31px;
}
.line-height11 {
  line-height: 11px;
}
.gap7_5 {
  gap: 7.5px;
}

.v-a-mid {
  vertical-align: middle;
}
.v-a-top {
  vertical-align: top;
}
.ml2 {
  margin-left: 2px;
}
.v-a-baseline {
  vertical-align: baseline;
}

.gap2 {
  gap: 2px;
}

.option-item {
  width: 52px;
  text-align: center;
}
.not-logged-in-header-height {
  height: 163px;
}

.logged-in-header-height {
  height: 96px;
}

.profile-header-text {
  padding-top: 20;
  padding-bottom: 16px;
}

.logged-in-wrapper {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 450px);
}
.not-logged-in-wrapper {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 261px);
}

.margin-tb0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.log-out {
  width: fit-content;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 16px;
}
.margin-top4 {
  margin-top: 4px;
}

.color293A3A {
  color: #293a3a;
}

.spacer {
  min-height: 16px;
}

.h16 {
  height: 16px;
}
.term-conditon-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #2081c3;
}
.terms-padding {
  padding-top: 15px;
}
.border-card {
  border: 1px solid #eeeeee;
}

@media only screen and (min-width: 1024px) {
  .margin-top-n31 {
    margin-top: 0px;
  }
  .mt-n30px {
    margin-top: -108px;
  }
  .options-bar {
    height: auto;
    max-height: 100px;
  }
  .option-item {
    width: 102px;
    text-align: center;
  }
  .card-shadow {
    border: 1px solid #eeeeee;
  }
  .logged-in-wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
    min-height: calc(100vh - 550px);
  }
  .option-icons {
    scale: 1.3;
  }
  .status-bar {
    height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

.boxRadius10 {
  border-radius: 8px;
}

.d-card {
  padding: 16px 38px 14px 38px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
}

.d-padding {
  padding: 21px 24px;
}
.col-gap20 {
  column-gap: 20px;
}

.temp-header {
  padding: 10.5px 0px;
  background-color: #dfece9;
}

.gap21 {
  gap: 21px;
}

.margin-bottom196 {
  margin-bottom: 196px;
}
