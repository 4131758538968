.width-80 {
  width: 80%;
}
.padding-top4 {
  padding-top: 4px;
}
.gap21px {
  gap: 21px;
}
.gap149_5px {
  gap: 149.5px;
}
.edit-icon {
  transform: translate(0, -100%);
  right: 0;
}
.width-fit-content {
  width: fit-content;
}

.cover-height {
  height: 97px;
  min-height: 97px;
}
.profile-div {
  height: 60px;
  min-height: 60px;
  width: 60px;
  min-width: 60px;
}
.def-cover {
  background-image: linear-gradient(294.81deg, #b3d9d0 31.41%, #dfece9 123.51%);
}

.edit-icon-cover {
  right: 8px;
  bottom: 8px;
}

.cover-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.profile-img {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #b8bdbd;
  background-color: #e1e3e3;
}

.selectedColor {
  background: #dfece9;
  color: #0e5b48;
}
.margin-right12 {
  margin-right: 12px;
}
.cardWithButtonbackGround {
  background: #dfece9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.buttonCSS {
  background: #127960;
  border-radius: 6px;
}
.border-rad-30 {
  border-radius: 30px;
}

.def-option-style {
  padding: 4px 8px;
}
.errorCSS {
  color: #d42015;
}

.error-icon-style {
  vertical-align: bottom;
  margin-right: 5.5px;
}
.primaryIconHeightWidth {
  height: 20px;
  width: 20px;
}
.secondaryIconHeightWidth {
  height: 16px;
  width: 16px;
}
.iconHeightWidth14 {
  height: 14px;
  width: 14px;
}
.padding-left6 {
  padding-left: 6px;
}
.dotCSS {
  border-radius: 45%;
  margin-left: 4px;
  margin-right: 4px;
}
.right-input-fixed-text {
  right: 12px;
  top: 12px;
  z-index: 9;
}
.left-input-fixed-text {
  left: 12px;
  top: 12px;
  z-index: 9;
}
.backUnset {
  background: unset !important;
}

/* desktop css */

.width18px {
  width: 18px;
  height: 18px;
}
.desktopCssImp {
  padding-top: unset !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
}

/* desktopcss */

.font-size24 {
  font-size: 24px;
}
.line-height28 {
  line-height: 28px;
}

@media only screen and (min-width: 1024px) {
  .cover-height {
    height: 281px;
    min-height: 281px;
  }

  .profile-div {
    height: 214px;
    min-height: 214px;
    width: 214px;
    min-width: 214px;
    border: 2px solid #b8bdbd;
    border-radius: 50%;

    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1))
      drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.05))
      drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
  }

  .edit-icon {
    transform: translate(-100%, -100%);
  }

  .edit-icon-cover {
    right: 12px;
    bottom: 12px;
  }
  .iconHeightWidth14 {
    height: 16px;
    width: 16px;
  }
}
.input-style {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
}
.input-style-error {
  border: 1px solid #d42015;
}
.input-error-style {
  margin-top: 6px;
  padding-top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.004em;

  /* Alert/Danger/4 */

  color: #d42015;
}
.input-style-disable {
  background: #f6f6f6;
}