* {
    font-family: "Roboto", sans-serif;
  }
  .w-79 {
    width: 79%;
  }
  .userCount {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005rem;
    color: #1f2223;
  }
  .userHeading {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005rem;
    color: #1f2223;
  }
  .tableContainer {
    overflow-x: scroll;
    width: 100%;
    border-radius: 8px;
    scrollbar-width: thin;
  }
  /* .tableContainerScroll{
    scroll
  } */
  .table {
    display: flex;
    flex-direction: column;
    width: max-content;
    border-collapse: collapse;
    /* overflow-x: scroll; */
    scrollbar-width: thin;
    /* margin-right: 20px; */
    /* min-width: 1000px; Ensure the table is wider than the container to enable scrolling */
  }
  
  .table-row {
    display: flex;
    width: max-content;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #4c4c4c;
    border: 1px solid #e5efff;
  }
  
  .table-cell {
    /* flex: 1; */
    padding: 16px;
    border-radius: 8px;
    min-width: 220px;
    width: 220px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #111313;
    /* border: 1px solid #ddd; */
  }
  .datarows {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #4c4c4c;
    overflow-wrap: anywhere;
  }
  .pointer {
    cursor: pointer;
  }
  
  .lightPink {
    background-color: #ffeeee !important;
  }
  
  .header {
    background-color: #f0f6ff;
    font-weight: bold;
  }
  
  .even {
    background-color: #fff;
  }
  
  .odd {
    /* background-color: #fff; */
    background-color: #FAFCFF;
  
    /* background-color: #f9f9f9; */
  }
  
  /* Additional styles to mimic the provided image */
  .table-row .table-cell:nth-child(1) {
    flex: 1; /* Adjust based on your requirements */
  }
  
  .table-row .table-cell:nth-child(2),
  .table-row .table-cell:nth-child(3),
  .table-row .table-cell:nth-child(4),
  .table-row .table-cell:nth-child(5),
  .table-row .table-cell:nth-child(6),
  .table-row .table-cell:nth-child(7) {
    flex: 1; /* Adjust based on your requirements */
  }
  
  .totalUser {
    display: flex;
    justify-content: center;
    gap: 3px;
    align-items: center;
  }
  
  /* Button.css */
  .custom-button:hover {
    background-color: #45a049;
  }
  
  .buttonClasses {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
  
  .header-div {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    margin-bottom: 14px;
  }
  
  .custom-button {
    margin-left: 16px;
  }
  
  .tableDiv {
    border: 1px solid #e5efff;
    margin-left: 20px;
    /* margin-right: 20px; */
  }
  
  .config-container {
    border: 1px solid #e1e3e3;
    position: absolute;
    right: 20px;
    background-color: white;
  }
  .label-div {
    overflow-y: auto;
    overflow-x: hidden;
    height: 128px;
    width: 270px;
    padding-top: 12px;
  }
  .checklabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.005rem;
    color: #4d5557;
  }
  
  .label {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 16px;
    padding-top: 12px;
  }
  .searchbar {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    border: 1px solid #e1e3e3;
  }
  .saveButton {
    width: 100%;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    border-radius: 6px;
    background-color: #0e5b48;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-left: 16px;
    margin-right: 16px;
    border: none;
  }
  .saveButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .d-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .dflexarchivebutton {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
  }
  .marginleftright28 {
    margin-right: 28px;
    margin-left: 28px;
  }
  
  .viewlink {
    color: blue !important;
    text-decoration: underline !important;
  }
  .kebab-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 24px; /* Adjust width as needed */
    height: 20px; /* Adjust height as needed */
    cursor: pointer;
  }
  
  .dot {
    width: 3px; /* Size of each dot */
    height: 3px;
    background-color: black; /* Color of the dots */
    border-radius: 50%; /* Makes the dots circular */
  }
  