.icon-style{
    width: 12px;
    height: 12px;
}

@media screen and (min-width:1023px) {
    .icon-style{
        width: 18px;
        height: 18px;
    }  
}