.text-truncate-file-upload {
    max-width: calc(100vw - 112px);
    display: block !important;
  
    /* text-overflow: ellipsis; */
    /* display: -webkit-box; */
    /* -webkit-line-clamp: 1; */
    /* /* -webkit-box-orient: vertical; */
  }
  .input-border {
    border: 1px solid #e1e3e3 !important;
  }
  .btn-styles{
    height: 40px;
  }
  .back-arrow {
    height: 24px;
    width: 24px;
  }
  .header-style {
    height: 64px;
  }

  .mob-astrik{
    margin-left: 2px;
    margin-top: -8px!important;
  }
  .upload-placeholder-color{
    color: #111313!important;
  }

  @media screen and (min-height:1024px) {
    .btn-styles{
      width: 326px;
      height: 36px;
      padding:9px 0px;
    }
  }